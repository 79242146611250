import React from 'react';
import { Container } from '@chakra-ui/react';
import HomeLayout from '../../../components/layouts/homelayout';
import Seo from '../../../components/sections/seo';

import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';

import pfasBannerBkgImg from '../../../components/ui/corporateresponsibility/responsiblepractices/pfas/pfas_banner_bkg.png'
import pfasBannerBkgImgSm from '../../../components/ui/corporateresponsibility/responsiblepractices/pfas/pfas_s_screen.jpg'

export default function Pfas() {
    return (
        <HomeLayout>
            <Seo
                title="Corporate Responsibility - Supply Chain Transparency Map"
                description="Utilizing measurement tools and systems allows us to be transparent about our practices to stakeholders. "
                //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
            />

            <section className='hero page-banner'>
                <div className='hero__image-wrapper'>
                    <picture>
                        <source media='(min-width: 768px)' srcset={pfasBannerBkgImg} />
                        <img className='hero__bkg-image' src={pfasBannerBkgImgSm} alt='' />
                    </picture>
                    
                </div>
                <div className='hero__container'>
                    <div className='hero__wrapper'>
                        <div className='hero__row'>
                            <div className='hero__content-container'>
                                <div className='hero__content'>
                                    <h1>Eliminating PFAS</h1>
                                    <p>We're dedicated to eliminating PFAS from the products we make without compromising the quality.</p>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="hero-main__overlay"></div>
            </section>

            <Container maxW="4xl" my={'var(--space-2xl)'} className='flow'>

                <div class="action-list">
                    <div id='timeline' class="bubble-icon">
                        <ViewTimelineOutlinedIcon className='icon-in-bubble' fill='var(--dark-blue)' />
                    </div>
                    
                    <div>
                        <h2>Timeline</h2>
                        <p>
                            Columbia Sportswear Company (CSC) is phasing out products designed with per- and polyfluoroalkyl substances (PFAS).  Our intent is to stop manufacturing any apparel or footwear with PFAS prior to our Fall '24 season. This transition is expected to impact the flow of our wholesale business and how we and others manage through existing inventory.​
                        </p>
                    </div>
                </div>
                
                <div class="action-list">
                    <div id='summary' class="bubble-icon">
                        <SummarizeOutlinedIcon className='icon-in-bubble' />
                    </div>
                    <div>
                        <h2>Performance Summary</h2>
                        <p>
                            Rigorous testing and evaluation of PFAS-free products by CSC technical personnel has determined water repellency and durability performance to be equivalent to or better than products utilizing PFAS chemistry.  The durability performance, and any other performance metric, over the lifetime of a given product will depend on specific usage and care scenarios and can vary from user to user. ​
                        </p>
                    </div>
                </div>
                
                <div class="action-list">
                    <div id='list' class="bubble-icon">
                        <ChecklistOutlinedIcon className='icon-in-bubble' />
                    </div>
                    <div>
                        <h2>Performance Expectations​</h2>
                        <p>The following depicts performance expectations of CSC product based upon the testing and evaluation by CSC technical personnel:</p>
                        <ol>
                            <li>PFAS-free DWR repels water just as well as PFAS-containing DWR​</li>
                            <li>Durability to home laundering for water repellency is the same for PFAS-free products and PFAS-containing products​</li>
                            <li>Care instructions should be followed for best long-term performance​</li>
                        </ol>
                    </div>
                </div>
                
            </Container>
            
            
        </HomeLayout>

    );
}