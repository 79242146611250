import React from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import Home from './pages/home';
import OurBrands from './pages/careers/ourbrands'
import Careers from './pages/careers'
import TermsOfUse from './pages/termsofuse';
import OurHistory from './pages/our-company/our-history'
import StrategyMissionValues from './pages/our-company/strategy-mission-values';
import Leadership from './pages/our-company/leadership'
import CorporateResponsibilityOverview from './pages/corporate-responsibility-group'
import CorporateResponsibilityImpact from './pages/corporate-responsibility-group/impact'
import InclusionDiversity from './pages/our-company/inclusion-diversity'
import SupplyChain from './pages/corporate-responsibility-group/responsible-practices/supplychain';
import LeveragingInnovation from './pages/corporate-responsibility-group/responsible-practices/leveraginginnovation';
import OurRegions from './pages/careers/regions-group';
import Distribution from './pages/careers/distribution';
import China from './pages/careers/china';
import Wellbeing from './pages/our-company/wellbeing-benefits';
import ElevatingResponsibilty from './pages/corporate-responsibility-group/responsible-practices/elevatingresponsibility';
import CleanWater from './pages/corporate-responsibility-group/stories/cleandrinkingwater';
import TransitionProjects from './pages/corporate-responsibility-group/stories/transition-projects';
import CollegiateOutdoor from './pages/corporate-responsibility-group/stories/collegiateoutdoorprogram';
import Club1938 from './pages/corporate-responsibility-group/stories/club1938';
import RepairMountainHardwear from './pages/corporate-responsibility-group/stories/repairatmountainhardwear';
import Pfas from './pages/corporate-responsibility-group/responsible-practices/pfas';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/careers',
    element: <Careers />
  },
  {
    path: '/careers/our-brands-group',
    element: <OurBrands />
  },
  {
    path: '/termsofuse',
    element: <TermsOfUse />
  },
  {
    path: '/our-company/our-history',
    element: <OurHistory />
  },
  {
    path: '/our-company/strategy-mission-values',
    element: <StrategyMissionValues />
  },
  {
    path: '/our-company/leadership',
    element: <Leadership />,
  },
  {
    path: '/our-company/inclusion-diversity',
    element: <InclusionDiversity />
  },
  {
    path: '/careers/our-brands-group/tab/tabgroupb/sorel',
    element: <Navigate to='/careers/our-brands-group/?tab-9d37deaf387dfeb99f5=' />
  },
  {
  path: '/corporate-responsibility/overview',
  element: <Navigate to='/corporate-responsibility-group/?tab-7924f1d2e71de065b6f=' />
  },
  {
    path: '/corporate-responsibility/impact',
    element: <CorporateResponsibilityImpact />
  },
  {
    path: '/corporate-responsibility/impact/stories/clean-drinking-water-program',
    element: <CleanWater />
  },
  {
    path: '/corporate-responsibility/impact/stories/transition-projects',
    element: <TransitionProjects />
  },
  {
    path: '/corporate-responsibility/impact/stories/collegiate-outdoor-recreation-program',
    element: <CollegiateOutdoor />
  },
  {
    path: '/corporate-responsibility/impact/stories/club-1938',
    element: <Club1938 />
  },
  {
    path: '/corporate-responsibility/impact/stories/repair-services-at-mountain-hardwear',
    element: <RepairMountainHardwear />
  },
  {
    path: '/corporate-responsibility/empowering-people',
    element: <Navigate to='/corporate-responsibility-group/?tab-6b450decc1cd295a70a=' />
  },
  {
    path: '/corporate-responsibility/sustaining-places',
    element: <Navigate to='/corporate-responsibility-group/?tab-19017f87daea3a103ca=' />
  },
  {
    path: '/corporate-responsibility/responsible-practices',
    element: <Navigate to='/corporate-responsibility-group/?tab-b57f0800d43fff2925c=' />
  },
  {
    path: '/corporate-responsibility/highlights',
    element: <Navigate to='/corporate-responsibility-group/?tab-bd5d2f27b7afd43e2d1=' />
  },
  {
    path: '/corporate-responsibility-group',
    element: <CorporateResponsibilityOverview />
  },
  {
    path: '/corporate-responsibility-group/responsible-practices/supply-chain',
    element: <SupplyChain />
  },
  {
    path: '/corporate-responsibility-group/responsible-practices/leveraging-innovation',
    element: <LeveragingInnovation />
  },
  {
    path: '/corporate-responsibility-group/responsible-practices/elevating-responsibility',
    element: <ElevatingResponsibilty />
  },
  {
    path: '/corporate-responsibility-group/responsible-practices/pfas',
    element: <Pfas />
  },
  {
    path: '/careers/regions-group',
    element: <OurRegions />
  },
  {
    path: '/careers/regions-group/north-america',
    element: <Navigate to='regions-group/?tab-c40defabe206bb77568' />
  },
  {
    path: '/careers/regions-group/asia',
    element: <Navigate to='regions-group/?tab-92a940378463b5a55aa' />
  },
  {
    path: '/careers/regions-group/europe',
    element: <Navigate to='regions-group/?tab-8a6e0fbdfb7865841e4' />
  },
  {
    path: '/careers/distribution',
    element: <Distribution />
  },
  {
    path: '/china',
    element: <China />
  },
  {
    path: '/our-company/wellbeing-benefits/',
    element: <Wellbeing />
  }
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
